@import "basics";

.alert {
    position: fixed;
    width: 100%;
    z-index: 100;
}

#wizardContiner {
    font-size: 16px;
    background-color: #FFF;

    label {
        font-size: 16px;
    }

    > .row {
        width: 100vw;
        
        @include media-breakpoint-down(sm) {
            display: flex;
            flex-direction: column-reverse;
        }
    }
}

#wizardImage {
    
    @include media-breakpoint-down(sm) {
        margin-top: 70px;
    }
}

#wizardContent {
    > div {
        max-width: 430px;
    }

    @include media-breakpoint-down(sm) {
        margin-top: 50px;
        position: inherit;
    }
    
    
    display: flex;
    align-items: center;
    justify-content: center;
    h1 {
        font-size: 36px;
        z-index: 100;
        
        @include media-breakpoint-down(sm) {
            padding: 10px;
            position: absolute;
            top: 0px;
            font-size: 22px;
            text-align: center;
            left: 0px;
            width: 100%;
        }
    }
}

#wizardIntro {
    height: 100vh;
    flex-direction: column;

    @include media-breakpoint-down(sm) {
        margin-top: 100px;
    }
    
    > div {
        width: 60%;

        &:first-child {
            border-radius: 0px 0px 8px 8px;
            box-shadow: 0px 8px 16px rgba(218, 218, 218, 0.51);
        }
    }

    a {
        color: #F18D27;
    }
}

#wizardImage img {
    height: 100vh;
    width: 100%;
    object-fit: cover;

    @include media-breakpoint-down(sm) {
        height: 390px;
        width: 100%;
    }
}

.my-custom-class-for-errors {
    color: red;
    ul {
        padding-left: 0px;
        list-style: none;
    }
}

label.error {
    color: red;
    font-size: 11px;
}